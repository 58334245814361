export const calculateTransparencyScore = (valueChain) => {
  const totalCount = 13;
  let verifiedSupplierScore = 0;
  Object.values(valueChain).forEach((item) => {
    if (Array.isArray(item)) {
      verifiedSupplierScore +=
        item.filter((supplier) => supplier.account?.verified).length /
        (item.length || 1);
    } else {
      verifiedSupplierScore += item && item.account?.verified ? 1 : 0;
    }
  });

  return Math.min(Math.round((verifiedSupplierScore / totalCount) * 100), 99);
};

export const calculateCircularityRange = (valueChain) => {
  const totalCount = 5;
  const keys = ["donations", "repairs", "upcycles", "recycles", "resales"];
  let circularityScore = 0;

  keys.forEach((key) => {
    if (valueChain[key] && valueChain[key].length > 0) {
      circularityScore++;
    }
  });

  if (circularityScore >= (2 * totalCount) / 3) {
    return "high";
  } else if (circularityScore >= (1 * totalCount) / 3) {
    return "medium";
  } else {
    return "low";
  }
};

export const calculateNumberOfPartners = (valueChain) => {
  let partners = [];
  Object.values(valueChain).forEach((item) => {
    if (Array.isArray(item)) {
      partners = partners.concat(item.map((supplier) => supplier.id));
    } else {
      item ? partners.push(item.id) : null;
    }
  });

  return new Set(partners).size;
};
