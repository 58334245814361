<template>
  <div>
    <div
      class="value-chain-circle"
      :style="`width: ${radius * 2}px; height: ${
        radius * 2
      }px; background-color: ${background};`"
    >
      <div
        v-if="isEditMode || items.length"
        class="value-chain-content-container"
      >
        <div
          v-for="(item, index) in items"
          :key="`${index}-icon`"
          class="value-chain-item"
          :style="`left: ${radius - 30}px; transform: rotate(${
            (180 * index) / items.length
          }deg) translate(calc(${radius * 2}px * cos(${
            90 - (180 * index) / items.length
          }deg))) rotate(-${(180 * index) / items.length}deg)`"
        >
          <trace-services-icon :traceName="item.icon" />
        </div>
        <div
          v-for="(item, index) in items"
          :key="`${index}-content`"
          class="value-chain-content"
          :style="`text-align: ${
            (360 * index) / items.length === 0 ||
            (360 * index) / items.length === 180
              ? 'center'
              : (360 * index) / items.length > 180
              ? 'right'
              : 'left'
          }; top: ${radius - 35}px; left: ${
            radius -
            ((360 * index) / items.length > 180
              ? 130 / 2 + 40
              : (360 * index) / items.length == 0 ||
                (360 * index) / items.length == 180
              ? 130 / 2
              : 30)
          }px; transform: rotate(${
            (360 * index) / items.length - 90
          }deg) translate(${radius + 95}px) rotate(${-(
            (360 * index) / items.length -
            90
          )}deg)`"
        >
          <div class="content-title">
            {{ item.label }}
          </div>
          <v-chip
            v-if="!isEditMode"
            color="white"
            :style="`justify-content: ${
              (360 * index) / items.length === 0 ||
              (360 * index) / items.length === 180
                ? 'center'
                : (360 * index) / items.length > 180
                ? 'flex-end'
                : 'flex-start'
            };`"
            class="content-dropdown"
          >
            <truncate-hovering
              v-if="Array.isArray(item.value)"
              :text="
                item.value.length > 1
                  ? `${item.value.length} selected`
                  : item.value[0]?.name
              "
              :separate-hover-text="
                item.value.length > 1
                  ? Array.from(item.value)
                      .map((value) => value.name)
                      .join(', ')
                  : ''
              "
              :length="18"
            />
            <truncate-hovering v-else :text="item.value?.name" :length="18" />
          </v-chip>
          <v-autocomplete
            v-else
            class="value-chain-company-selection"
            :style="`width: ${(150 * radius) / 200}px;`"
            background-color="white"
            rounded
            :search-input="
              focusedDropdown === item.id ? dropdownOptions.search : ''
            "
            @update:search-input="updateDropdownSearch"
            v-model="item.value"
            :items="getAutocompleteItems(item)"
            item-text="name"
            item-value="id"
            label="Add company"
            :multiple="item.multiple"
            :hide-no-data="dropdownOptions.loading"
            :loading="dropdownOptions.loading && focusedDropdown === item.id"
            :disabled="item.id === 'delivery'"
            @focus="focusedDropdown = item.id"
            @change="onChangeInput(item, ...arguments)"
            dense
            chips
            small-chips
            clearable
            return-object
          >
            <template v-slot:selection="data">
              <div
                v-if="!item.multiple"
                v-bind="data.attrs"
                class="select-component"
              >
                <truncate-hovering :text="data.item.name" :length="8" />
              </div>
              <div
                v-else-if="item.multiple && data.index === 0"
                class="select-component"
              >
                <truncate-hovering
                  :text="`${item.value.length} selected`"
                  :separate-hover-text="
                    item.value.map((value) => value.name).join(', ')
                  "
                  :length="8"
                />
              </div>
            </template>

            <template v-slot:append-item>
              <infinite-loading
                ref="InfiniteLoading"
                @infinite="getGlobalSuppliersList"
                :key="`infinite-toggle-${dropdownOptions.search}`"
              >
                <div slot="no-more" v-if="dropdownOptions.list.length">
                  No more data
                </div>
                <div slot="no-more" v-if="!dropdownOptions.list.length">
                  No data available
                </div>
                <div slot="no-results" v-if="dropdownOptions.list.length">
                  No more data
                </div>
                <div slot="no-results" v-if="!dropdownOptions.list.length">
                  No data available
                </div>
              </infinite-loading>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div
        class="value-chain-title"
        :style="`
        width: ${radius * 2 - 60}px;
        overflow-wrap: break-word;
        font-size: ${getFontSize(
          title && title.length < 40 ? title.length : 40,
          radius
        )}px;
        line-height: 1.2;
      `"
      >
        {{ title | truncate(40, "...") }}
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import debounce from "lodash/debounce";
import restAdapter from "@/restAdapter";

export default {
  name: "ValueChainCircle",
  components: {
    InfiniteLoading,
  },
  props: {
    background: {
      type: String,
      default: "transparent",
    },
    radius: {
      type: Number,
      default: 200,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    values: {
      handler(values) {
        this.valueChain.forEach((item) => {
          if (values[item.id]) {
            item.value = values[item.id];
          } else {
            item.value = item.multiple ? [] : null;
          }
        });
      },
      immediate: true,
      deep: true,
    },
    focusedDropdown: {
      handler(value) {
        this.dropdownOptions = {
          ...this.dropdownOptions,
          page: 1,
          search: "",
          loading: false,
        };
        if (value) {
          this.getGlobalSuppliersList();
        }
      },
    },
  },
  filters: {
    truncate: function (text, stop, clamp) {
      if (stop > 3 && stop < text.length) {
        return text.slice(0, stop - 3) + clamp || "...";
      }
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  computed: {
    items() {
      if (this.isEditMode) {
        return this.valueChain;
      } else {
        return this.valueChain.filter((item) => {
          return Array.isArray(item.value) ? item.value.length : item.value;
        });
      }
    },
  },
  mounted() {
    if (this.isEditMode) {
      this.getGlobalSuppliersList();
    }
  },
  data() {
    return {
      dropdownOptions: {
        list: [],
        page: 1,
        search: "",
        loading: false,
      },
      focusedDropdown: null,
      valueChain: [
        {
          id: "design",
          label: "Design",
          icon: "DesignIcon",
          multiple: false,
          value: null,
        },
        {
          id: "rawMaterial",
          label: "Raw Material",
          icon: "RawMaterialIcon",
          multiple: false,
          value: null,
        },
        {
          id: "spinning",
          label: "Spinning",
          icon: "SpinningIcon",
          multiple: false,
          value: null,
        },
        {
          id: "weaving",
          label: "Weaving",
          icon: "WeavingIcon",
          multiple: false,
          value: null,
        },
        {
          id: "dyeing",
          label: "Dyeing",
          icon: "DyeingIcon",
          multiple: false,
          value: null,
        },
        {
          id: "sampling",
          label: "Sampling",
          icon: "SamplingIcon",
          multiple: false,
          value: null,
        },
        {
          id: "trims",
          label: "Trims",
          icon: "TrimsIcon",
          multiple: false,
          value: null,
        },
        {
          id: "manufacturing",
          label: "Manufacturing",
          icon: "ManufacturingIcon",
          multiple: false,
          value: null,
        },
        {
          id: "donations",
          label: "Donation",
          icon: "DonationsIcon",
          multiple: true,
          value: [],
        },
        {
          id: "repairs",
          label: "Repair",
          icon: "RepairsIcon",
          multiple: true,
          value: [],
        },
        {
          id: "upcycles",
          label: "Upcycle",
          icon: "UpcyclesIcon",
          multiple: true,
          value: [],
        },
        {
          id: "recycles",
          label: "Recycle",
          icon: "RecyclesIcon",
          multiple: true,
          value: [],
        },
        {
          id: "resales",
          label: "Resale",
          icon: "ResalesIcon",
          multiple: true,
          value: [],
        },
      ],
    };
  },
  methods: {
    updateDropdownSearch(value) {
      if (this.dropdownOptions.search !== value) {
        this.dropdownOptions.search = value;
        this.dropdownOptions.loading = true;
        this.debounceSearch();
      }
    },
    getFontSize(length, radius) {
      const smallTitleThreshold = radius / 8;
      const mediumTitleThreshold = radius / 6.5;
      if (length <= smallTitleThreshold) {
        return radius / 5;
      } else if (length <= mediumTitleThreshold) {
        return (radius / 5) * 0.85;
      } else {
        return (radius / 5) * 0.7;
      }
    },
    getGlobalSuppliersList($state = null) {
      this.dropdownOptions.loading = true;
      const url = `/api/organization_registry?page=${
        $state ? this.dropdownOptions.page + 1 : 1
      }&type=supplier&name=${
        this.dropdownOptions.search ? this.dropdownOptions.search : ""
      }`;

      restAdapter.get(url).then((response) => {
        this.dropdownOptions.loading = false;
        if (response.data.data.length) {
          this.dropdownOptions.list = $state
            ? this.dropdownOptions.list.concat(response.data.data)
            : response.data.data;
          this.dropdownOptions.page = response.data.current_page;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    debounceSearch: debounce(function () {
      this.getGlobalSuppliersList();
    }, 600),
    onChangeInput(item, value) {
      this.$emit("update", { [item.id]: value });
    },
    getAutocompleteItems(item) {
      return (
        item.value && !Array.isArray(item.value)
          ? [item.value]
          : Array.isArray(item.value)
          ? [...item.value]
          : []
      ).concat(
        this.dropdownOptions.list.filter((listItem) =>
          item.value && !Array.isArray(item.value)
            ? listItem.id !== item.value.id
            : !item.value?.find((value) => value.id === listItem.id)
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.value-chain-circle {
  position: relative;
  margin: 130px 200px;
  border-radius: 50%;
  stroke-width: 2px;
  color: #473068;
  border: 5px solid #ea7a66;
  outline: 5px solid #ea7a66;
}

.value-chain-content-container {
  width: 100%;
  height: 100%;
}

.value-chain-item {
  position: absolute;
  top: -36px;
  text-align: center;
}

.value-chain-content {
  position: absolute;
}

.value-chain-title {
  position: absolute;
  white-space: normal;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: bold;
  color: #473068;
  text-transform: uppercase;
  margin-left: 5px;
}

.content-title {
  font-size: 17px;
  font-weight: bold;
  color: #473068;
}

.select-component {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px 0px;
}

.content-dropdown {
  display: flex;
  width: 130px;
  overflow: hidden;
  div {
    font-size: 13px;
  }
}

.value-chain-company-selection::v-deep .v-input__slot {
  padding: 0 10px;
}
</style>
